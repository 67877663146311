
img.logo {
    filter: brightness(0) invert(1);
}

.chat {
    .card {
        max-height: 500vh;
    }
}

body.chat-page {
    .page-header.page-header-small {
        min-height: 350px;
        max-height: 350px;
    }
    .table {
        .sent-message {
            max-width: 50%;
        }
        td {
            white-space: unset;
        }
    }
}

@media (max-width: 767.98px)  {
    .chat-page .upper {
        margin-top: -300px;
    }
}


.amplify-tabs {
    display: none !important;
}


[data-amplify-router] {
    margin-top: 100px;
}

.table-click tr {
    cursor: pointer;
}


.header-fix {
    position: fixed !important;
    left: 0;
    top: 0;
    right: 0;
    width: 100% !important;
}

.footer-link {
    margin: 0 2em 0 0 !important;
    display: inline-block;
}

.footer-fix {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    // color: white !important;
}

.amplify-field__show-password {
    border-radius: 0 5px 5px 0 !important;
}

[data-amplify-authenticator] {
    // --amplify-components-button-primary-background-color: "#246CF2" !important;
    --amplify-components-button-primary-background-color: hsl(219deg 89% 55%);
    --amplify-components-button-primary-hover-background-color: hsl(219deg 89% 45%);
    --amplify-components-button-font-weight: normal;
    --amplify-components-button-border-radius: 50px;
        
    // --amplify-colors-background-primary: var(--amplify-colors-neutral-90);
    // --amplify-colors-background-secondary: var(--amplify-colors-neutral-100);
    // --amplify-colors-background-tertiary: var(--amplify-colors-black);
    // --amplify-colors-border-primary: var(--amplify-colors-neutral-20);
    // --amplify-colors-border-secondary: var(--amplify-colors-neutral-40);
    // --amplify-colors-border-tertiary: var(--amplify-colors-neutral-60);
    // --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
    // --amplify-colors-brand-primary-20: var(--amplify-colors-teal-90);
    // --amplify-colors-brand-primary-40: var(--amplify-colors-teal-80);
    // --amplify-colors-brand-primary-60: var(--amplify-colors-teal-60);
    // --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
    // --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
    // --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
    // --amplify-colors-font-interactive: var(--amplify-colors-white);
    // --amplify-components-heading-color: var(--amplify-colors-neutral-20);
    // --amplify-components-tabs-item-active-border-color: var(
    //   --amplify-colors-white
    // );
    // --amplify-components-tabs-item-active-color: var(--amplify-colors-white);
    // --amplify-components-tabs-item-color: var(--amplify-colors-white);
    // --amplify-components-tabs-item-focus-color: var(--amplify-colors-white);
    // --amplify-components-text-color: var(--amplify-colors-font-interactive);
  }

